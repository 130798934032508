@media screen and (max-width:800px) {
   .rcw-conversation-container {
      min-width: 0px;
      max-width: 100vw;
   }
}

.cs-conversation-header__content {
   margin-left: 35px
}

.cs-conversation-header {
   background-color: #0137b3;
   font-family: "MontserratMedium";
   color: #fff;
   font-size: 20px;
}

.cs-message--avatar-tl .cs-message__avatar {
   justify-content: flex-start;
   order: 0;
   margin-right: 16px;
   margin-left: -4px;
}

.cs-message--avatar-tr .cs-message__avatar {
   justify-content: flex-start;
   order: 1;
   margin-left: -2px;
   margin-right: 10px;
}

.app-chatbot-button {
   width: 60px;
   height: 60px;
   border-radius: 30px;
   padding: 10px;
   color: #fff;
   background-color: #0137b3;
   border: none;
   position: fixed;
   bottom: 110px;
   display: flex;
   flex-direction: column;
   align-items: center;
   transition: transform 0.6s ease-in-out;
   right: 11px;
}

.app-chatbot-button:hover {
   box-shadow: 0 0 27px 1.5px rgba(0, 0, 0, .2);
}

.app-chatbot-button-icon {
   fill: #fff;
}

.cs-main-container {
   width: 425px;
   height: calc(100% - 120px);
   max-height: 80%;
   position: fixed;
   right: 10px;
   bottom: 25px;
   box-sizing: border-box;
   background: #fff;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   transition: .3s ease-in-out;
   border-radius: 10px;
   box-shadow: none !important;
   z-index: 100;
}

.cs-message__content {
   font-family: "MontserratMedium";
   font-size: 13px;
}

.cs-message-input__content-editor {
   font-family: "MontserratMedium";
   background-color: #ffffff;
   color: #000000;
}

.cs-message-input__content-editor-wrapper {
   background-color: #ffffff;
   border: 1px solid rgb(230, 230, 230);
}

.icono {
   width: 40px;
   height: 40px;
}

.cs-message--incoming .cs-message__content {
   color: rgba(0, 0, 0, .87);
   background-color: #ffffff;
   border-radius: 0.7em 0.7em 0.7em 0.7em;
   border: 1px solid rgb(230, 230, 230);
   font-family: "MontserratMedium";
}

.cs-message--outgoing .cs-message__content {
   color: rgba(0, 0, 0, .87);
   background-color: #ffffff;
   border-radius: 0.7em 0.7em 0.7em 0.7em;
   border: 1px solid rgb(230, 230, 230);
   font-family: "MontserratMedium";
}

.cs-message {
   font-family: "MontserratMedium";
}

.cs-button--attachment {
   color: rgb(118, 118, 118);
}

.cs-button--send {
   color: rgb(118, 118, 118);
}

.file-button {
   font-family: "MontserratMedium";
   color: #000000;
}

.btn-menu {
   width: 100%
}

.btn-borde {
   padding: .375rem .75rem;
   border: 1px solid #0137b3;
   border-radius: .25rem;
   color: #000000;
   transition: color .15s ease-in-out,
      background-color .15s ease-in-out;
   font-family: "MontserratMedium";
   font-size: 13px;
   margin-top: 10px;
   background-color: #ffffff;
   width: 100%
}

.btn-borde:hover,
.btn-borde:active,
.btn-borde:focus {
   color: #ffffff;
   background-color: #0137b3;
}

.app-chatbot-button-header {
   width: auto;
   height: auto;
   border-radius: 30px;
   padding: 10px;
   color: #fff;
   background-color: #0137b3;
   border: none;
   bottom: 25px;
   display: flex;
   flex-direction: column;
   align-items: center;
   transition: transform 0.6s ease-in-out;
   right: 5px;
}

.icon-2 {
   width: 30px;
   height: 30px;
}

#div-button {
   width: auto;
   height: auto;
   border-radius: 30px;
   color: #fff;
   background-color: #0137b3;
   border: none;
   display: flex;
   flex-direction: column;
   transition: transform 0.6s ease-in-out;
   align-items: flex-end;
   padding: 1px !important;
}

.div-title {
   width: auto;
   height: auto;
   color: #fff;
}

.div-header {
   display: grid;
   grid-template-columns: 3fr 1fr;
   gap: 10px;
}

.app-file-button {
   display: none;
}

.APP {
   position: relative
}

.cs-typing-indicator__dot {
   background-color: #0137b3;
}

.cs-typing-indicator__text {
   color: #0137b3;
   font-family: "MontserratMedium";
}

@font-face {
   font-family: "MontserratMedium";
   src: url("./react-chat/assets/fonts/Montserrat-Medium.otf");
}

@font-face {
   font-family: "MontserratBold";
   src: url("./react-chat/assets/fonts/Montserrat-Bold.ttf");
}

@media (max-width: 450px) {
   .cs-main-container {
      width: 100%;
      height: 100%;
      max-height: 100%;
      right: 0;
      bottom: 0;
      border-radius: 0;
      transition: .1s ease-in-out;
   }
}